// src/context/UserContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

function useUser() {
  return useContext(UserContext);
}

function UserProvider(props) {
  const [user, setUser] = useState(null); // This is the user object
  const [isLoading, setIsLoading] = useState(true);
  let loginWindow;

  useEffect(() => {
    // Check if user data is stored in local storage
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      // If user data is stored, parse it and set it as the initial state

      // Check if users session has expired
      const parsedUser = JSON.parse(storedUser);
      if (parsedUser?.bearerToken && !isTokenExpired(parsedUser.bearerToken)) {
        setUser(JSON.parse(storedUser));
      } else {
        // If the token has expired, clear the stored user data
        localStorage.removeItem("user");
      }
    }
    // Set loading state to false when initial state is set
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // Check if the user is still being loaded
    if (isLoading) return;
    // When the user state changes, update the stored user data in local storage
    localStorage.setItem("user", JSON.stringify(user));
  }, [user, isLoading]);

  function login(user) {
    setUser(user);
  }

  function logout() {
    setUser(null);
  }

  const loginWindowEventListener = (event) => {
    if (event.origin !== "https://api.jeeves.bot") return;
    //Close the login window
    loginWindow.close();
    //Set the user
    if (event?.data?.authenticated) {
      login(event.data);
    }
  };

  function openLoginWindow() {
    if (loginWindow) {
      if (loginWindow.closed) {
        loginWindow = null;
      } else {
        loginWindow.focus();
        return;
      }
    }

    const width = 500;
    const height = 700;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`;

    loginWindow = window.open(
      "https://api.jeeves.bot/login",
      "loginWindow",
      options
    );

    window.addEventListener("message", loginWindowEventListener);

    // Close the listener when the new window is closed
    const checkWindowClosed = setInterval(() => {
      if (loginWindow.closed) {
        loginWindow = null;
        window.removeEventListener("message", loginWindowEventListener);
        clearInterval(checkWindowClosed);
      }
    }, 1000);
  }

  const value = {
    user,
    isLoading,
    openLoginWindow,
    login,
    logout,
  };

  return <UserContext.Provider value={value} {...props} />;
}

export { UserProvider, useUser };

function isTokenExpired(token) {
  const tokenData = JSON.parse(atob(token.split(".")[1])); // Decoding the token payload

  const currentTime = Math.floor(Date.now() / 1000); // Get the current time in seconds

  return tokenData.exp < currentTime;
}
