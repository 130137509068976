import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
import { Typography, Grid, Tooltip, Button, Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import CharacterCard from "../components/CharacterCard";
import unknownCharacterAvatar from "../assets/unknown_character.png";

const useStyles = makeStyles({
  card: {
    height: 96, // The height of the avatar (64) + padding (16 top + 16 bottom)
    marginBottom: 16,
    position: "relative",
  },
  title: {
    fontSize: 14,
  },
  subTitle: {
    marginBottom: 12,
  },
  gridContainer: {
    marginTop: 16,
  },
  avatar: {
    width: 64,
    height: 64,
    borderRadius: "50%",
  },
  charInfo: {
    paddingTop: 4,
    paddingBottom: 0, // Add this line
  },
  actions: {
    position: "absolute",
    bottom: 16,
  },
  cardStatus: {
    position: "absolute",
    bottom: 8,
    right: 8,
  },
});

const CharacterSync = () => {
  const [socketId, setSocketId] = useState(null);
  const [socket, setSocket] = useState(null);
  const [success, setSuccess] = useState(false);
  const [reason, setReason] = useState("");
  const [battleNetToken, setBattleNetToken] = useState("");
  const [id, setId] = useState("");
  const [battleTag, setBattleTag] = useState("");
  const [characters, setCharacters] = useState([]);

  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setSuccess(queryParams.get("success") === "true");
    setReason(queryParams.get("reason"));
    setBattleNetToken(queryParams.get("battleNetToken"));
    setId(queryParams.get("id"));
    setBattleTag(queryParams.get("battletag"));

    const socketInstance = io(process.env.REACT_APP_JEEVES_API_URL);

    socketInstance.on("connect", () => {
      console.log("Connected");
      setSocketId(socketInstance.id);
    });

    socketInstance.on("BATTLENET_INFO", (event) => {
      console.log(event);
      const data = event.data;
      switch (event.code) {
        case "ACCOUNT_SYNC_STARTING":
          console.log("Account Sync Starting");
          break;
        case "DISCOVERY_STARTED":
          console.log("Discovery Started");
          break;
        case "CHARACTERS_DISCOVERED":
          console.log(event.data);
          setCharacters((prevState) => [...prevState, ...data.data]);
          break;
        case "CHARACTER_QUEUED":
          setCharacters((prevState) =>
            prevState.map((char) =>
              char.id === data.id && char.region === data.region
                ? { ...char, status: "Queued" }
                : char
            )
          );
          break;
        case "CHARACTER_SCANNED":
          console.log("Character Scanned", data);
          setCharacters((prevState) =>
            prevState.map((char) =>
              char.id === data.id && char.region === data.region
                ? { ...char, ...data, status: "Scanned" }
                : char
            )
          );
          break;
        case "CHARACTER_FAILED":
          console.log("Character Scan Failed", data);
          setCharacters((prevState) =>
            prevState.map((char) =>
              char.id === data.id && char.region === data.region
                ? { ...char, ...data, status: "Failed" }
                : char
            )
          );
          break;
        case "UNKNOWN_ERROR":
          setSuccess(false);
          setReason("An unknown error occurred. Please try again.");
          break;
        default:
          break;
      }
    });

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
    };
  }, [location.search]);

  useEffect(() => {
    if (success && socketId) {
      fetch(`${process.env.REACT_APP_JEEVES_API_URL}/battlenet/${id}/watch`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${battleNetToken}`,
          socket: socketId,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.status);
          console.log(data.code);
          console.log(data.status === "ok" && data.code === "WATCHING_ACCOUNT");
          if (data.status === "ok" && data.code === "WATCHING_ACCOUNT") {
            console.log("Watching Account");
          } else {
            setSuccess(false);
            setReason("Unable to watch account. Please try again.");
          }
        })
        .catch((err) => {
          //If 403 error, the token has most likely expired
          if (err.status === 403) {
            setSuccess(false);
            setReason(
              "This link has expired. If you are attempting to authorize generate a fresh link and try again."
            );
          } else {
          }
          console.error(err);
          setSuccess(false);
          setReason("Unable to watch account. Please try again.");
        });
    }
  }, [success, id, battleNetToken, socketId, socket]);

  const setCharacterAsMain = (character) => {
    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/battlenet/${id}/characters/${character.id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${battleNetToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          main: !character.main,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setCharacters((prevState) =>
            prevState.map((char) =>
              char.id === character.id && char.region === character.region
                ? { ...char, main: true }
                : { ...char, main: false }
            )
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const toggleCharaterIgnored = (character) => {
    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/battlenet/${id}/characters/${character.id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${battleNetToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ignored: !character.ignored,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setCharacters((prevState) =>
            prevState.map((char) =>
              char.id === character.id && char.region === character.region
                ? { ...char, ignored: !character.ignored }
                : char
            )
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const toggleCharacterHidden = (character) => {
    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/battlenet/${id}/characters/${character.id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${battleNetToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          hidden: !character.hidden,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setCharacters((prevState) =>
            prevState.map((char) =>
              char.id === character.id && char.region === character.region
                ? { ...char, hidden: !character.hidden }
                : char
            )
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (!success) {
    return (
      <Box textAlign="center">
        <Typography variant="h6" color="error">
          Something went wrong: {reason}
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <>
        <Box textAlign="center">
          <Typography variant="h4">{battleTag} Linked</Typography>
          <Typography variant="h6">
            We are scanning your characters now, you may close this window or
            keep it open to make changes.
          </Typography>
        </Box>
        <Box textAlign="center">
          <br />
          <Typography variant="h5" gutterBottom>
            Scanned Characters
          </Typography>
        </Box>
        <Grid container spacing={2} className={classes.gridContainer}>
          {characters
            .filter((char) => char.status === "Scanned")
            .map((char) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={char.id}>
                <CharacterCard
                  character={char}
                  renderButtons={(char) => (
                    <>
                      <Button
                        size="small"
                        onClick={() => setCharacterAsMain(char)}
                        style={{
                          fontWeight: char.main ? "bold" : "normal",
                        }}
                      >
                        {char.main ? "Main" : "Set Main"}
                      </Button>
                      <Tooltip title="Ignored characters are treated like they don't exist, they are in our database but we pretend they are not for all practical purposes">
                        <Button
                          size="small"
                          onClick={() => toggleCharaterIgnored(char)}
                        >
                          {char.ignored ? "Unignore" : "Ignore"}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Privacy Control - Hidden characters are only visible to YOU and that characters guild">
                        <Button
                          size="small"
                          onClick={() => toggleCharacterHidden(char)}
                        >
                          {char.hidden ? "Unhide" : "Hide"}
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  classes={classes}
                  unknownCharacterAvatar={unknownCharacterAvatar}
                />
              </Grid>
            ))}
        </Grid>
        <br />
        <Box textAlign="center">
          <Typography variant="h5" gutterBottom>
            Queued Characters
          </Typography>
        </Box>
        <Grid container spacing={2} className={classes.gridContainer}>
          {characters
            .filter((char) => char.status === "Queued")
            .map((char) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={char.id}>
                <CharacterCard
                  character={char}
                  classes={classes}
                  unknownCharacterAvatar={unknownCharacterAvatar}
                />
              </Grid>
            ))}
        </Grid>
        <br />
        {characters.filter((char) => char.status === "Failed").length > 0 && (
          <>
            <Box textAlign="center">
              <Typography variant="h5" gutterBottom>
                Unable to Scan
              </Typography>
              <Typography variant="h7" gutterBottom>
                Why? The most common reasons are that the character has not been
                logged into in a long time or that the character is too low of a
                level.
              </Typography>
            </Box>
            <br />
            <Grid container spacing={2} className={classes.gridContainer}>
              {characters
                .filter((char) => char.status === "Failed")
                .map((char) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={char.id}>
                    <CharacterCard
                      character={char}
                      classes={classes}
                      unknownCharacterAvatar={unknownCharacterAvatar}
                    />
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </>
    </div>
  );
};
export default CharacterSync;
