import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";

import { useServer } from "../context/ServerContext";
import { useServerSettings } from "../context/ServerSettingsContext";

function EnumeratedRoleMap() {
  const { server, serverRoles } = useServer();
  const { roleManagementSettings, setRoleManagementSettings, integrations } =
    useServerSettings();
  const { integrationSlug } = useParams();
  const [deletedRoles, setDeletedRoles] = useState([]);

  useEffect(() => {
    const selectedRoleIds = Object.values(
      roleManagementSettings?.integrations?.[integrationSlug]?.roleMap || {}
    ).flat();
    const validRoleIds = serverRoles.map((role) => role.id);
    const invalidRoleIds = selectedRoleIds.filter(
      (id) => !validRoleIds.includes(id)
    );

    setDeletedRoles(
      invalidRoleIds.map((id) => ({
        id,
        name: "@Deleted-Role",
      }))
    );
  }, [roleManagementSettings, serverRoles, integrationSlug]);

  const maxSelectItems =
    server.premium === true &&
      integrations?.[integrationSlug]?.roleMap?.maxEach > 1
      ? integrations?.[integrationSlug]?.roleMap?.maxEach
      : 1;

  const handleSelectChange = (itemId, event) => {
    const selectedRoles = Array.isArray(event.target.value)
      ? event.target.value
      : [event.target.value]; // Ensure roles are stored as an array

    if (maxSelectItems === 1 || selectedRoles.length <= maxSelectItems) {
      setRoleManagementSettings({
        ...roleManagementSettings,
        integrations: {
          ...roleManagementSettings.integrations,
          [integrationSlug]: {
            ...roleManagementSettings.integrations[integrationSlug],
            roleMap: {
              ...roleManagementSettings.integrations[integrationSlug].roleMap,
              [itemId]: selectedRoles, // Always store roles as an array
            },
          },
        },
      });
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      {integrations[integrationSlug].roleMap.items.map((item, index) => (
        <Box key={index} sx={{ pb: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                {item.name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="streamer-role">
                  {item.name} Role
                </InputLabel>
                <Select
                  label="Choose Role"
                  multiple={maxSelectItems > 1 ? true : false}
                  value={
                    Array.isArray(
                      roleManagementSettings?.integrations?.[integrationSlug]
                        ?.roleMap[item.id]
                    )
                      ? roleManagementSettings.integrations[integrationSlug]
                        .roleMap[item.id]
                      : [] // Default to an empty array
                  }
                  onChange={(event) => handleSelectChange(item.id, event)}
                >
                  {[...serverRoles, ...deletedRoles].map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}

export default EnumeratedRoleMap;
