// src/components/MarkdownRenderer.js
import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Typography, Box, Link } from "@mui/material";

const MarkdownRenderer = ({ mdPath }) => {
  const [markdown, setMarkdown] = useState("");

  const ImageRenderer = ({ src, alt }) => {
    // Extract the directory path from the markdown file path
    const dirPath = mdPath.split("/").slice(0, -1).join("/");

    // Construct the new src by combining the directory path and the original src
    const newSrc = `/documentation/docs/${dirPath}/${src}`;

    return <img src={newSrc} alt={alt} />;
  };

  const MarkdownComponents = {
    p: ({ children }) => (
      <Typography variant="body1" paragraph>
        {children}
      </Typography>
    ),
    h1: ({ children }) => (
      <Box sx={{ borderBottom: "1px solid #eaecef", mb: 1 }}>
        <Typography variant="h4" gutterBottom>
          {children}
        </Typography>
      </Box>
    ),
    h2: ({ children }) => (
      <Box sx={{ borderBottom: "1px solid #eaecef", mb: 1 }}>
        <Typography variant="h5" gutterBottom>
          {children}
        </Typography>
      </Box>
    ),
    h3: ({ children }) => (
      <Typography variant="h6" gutterBottom>
        {children}
      </Typography>
    ),
    h4: ({ children }) => (
      <Typography variant="subtitle1" gutterBottom>
        {children}
      </Typography>
    ),
    h5: ({ children }) => (
      <Typography variant="caption" display="block" gutterBottom>
        {children}
      </Typography>
    ),
    a: ({ children, ...props }) => <Link {...props}>{children}</Link>,
    code: ({ children }) => (
      <Box
        component="span"
        sx={{
          bg: "rgba(27,31,35,0.05)",
          p: "0.2em 0.4em",
          borderRadius: "3px",
          fontFamily: "monospace",
          color: "#d7ba7d", // Pale yellow-ish color
        }}
      >
        {children}
      </Box>
    ),
    pre: ({ children }) => (
      <Box
        component="pre"
        sx={{
          bg: "rgba(27,31,35,0.05)",
          p: 2,
          borderRadius: "3px",
          overflowX: "auto",
          fontFamily: "monospace",
          color: "#d7ba7d", // Pale yellow-ish color
        }}
      >
        {children}
      </Box>
    ),
    ul: ({ children }) => (
      <Box component="ul" sx={{ pl: 5, mt: 1, mb: 2 }}>
        {children}
      </Box>
    ),
    ol: ({ children }) => (
      <Box component="ol" sx={{ pl: 5, mt: 1, mb: 2 }}>
        {children}
      </Box>
    ),
    li: ({ children }) => (
      <li>
        <Typography component="span">{children}</Typography>
      </li>
    ),
    blockquote: ({ children }) => (
      <Box
        component="blockquote"
        sx={{
          borderLeft: "4px solid #dfe2e5",
          pl: 3,
          fontStyle: "italic",
          mt: 2,
          mb: 2,
          color: "#6a737d",
        }}
      >
        {children}
      </Box>
    ),
    hr: () => (
      <Box
        component="hr"
        sx={{ border: "none", borderBottom: "1px solid #eaecef", my: 4 }}
      />
    ),
    img: ImageRenderer,
  };

  useEffect(() => {
    fetch(`/documentation/docs/${mdPath}`)
      .then((response) => response.text())
      .then((text) => setMarkdown(text))
      .catch((error) => console.error("Error fetching markdown:", error));
  }, [mdPath]);

  return (
    <Box sx={{ p: 4 }}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={MarkdownComponents}
      >
        {markdown}
      </ReactMarkdown>
    </Box>
  );
};

export default MarkdownRenderer;
