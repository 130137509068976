// src/components/DashboardCommandSettings.js
import React, { useEffect, useState } from "react";
import { Grid, Typography, Card, CardContent, Box } from "@mui/material";
import { useServer } from "../context/ServerContext";
import { useUser } from "../context/UserContext";
import LegacyWarning from "./LegacyWarning";

import DiscordIntegrationsPageImage from "../assets/Discord_Integrations_Page.png";

const DashboardCommandSettings = () => {
  const { server, serverChannels, serverRoles } = useServer();
  const { user } = useUser();
  const [commands, setCommands] = useState([]);

  useEffect(() => {
    if (server && user) {
      fetch(
        `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/settings/command`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.bearerToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "ok") {
            setCommands(data.data);
          }
        });
    }
  }, [server, user]);

  const renderCommandData = () => {
    if (commands.length === 0) return null;

    // Filter commands with legacy settings
    const legacyCommands = commands.filter(
      (command) =>
        !command.enabled || command.channels.length > 0 || command.roles.length > 0
    );

    // Group legacy commands
    const groupedCommands = legacyCommands.reduce((acc, command) => {
      if (!acc[command.group]) {
        acc[command.group] = [];
      }
      acc[command.group].push(command);
      return acc;
    }, {});

    const mentionStyle = {
      color: "#5865F2", // Discord mention blue color
    };

    return (
      <Box sx={{ maxWidth: 900, mb: 4 }}>
        <Box
          sx={{
            bgcolor: "background.paper",
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 1,
            p: 2,
          }}
        >
          {Object.entries(groupedCommands).map(([group, groupCommands]) => (
            <Box key={group} sx={{ mb: 4 }}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {group}:
              </Typography>
              <Grid container spacing={2}>
                {groupCommands.map((command) => (
                  <Grid item key={command.name} xs={12} sm={6} md={4}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h6">{command.name}</Typography>
                        {!command.enabled && (
                          <Typography variant="subtitle1" color="text.secondary">
                            Disabled
                          </Typography>
                        )}
                        {command.channels.length > 0 && (
                          <Typography variant="subtitle1">
                            Channels:{" "}
                            {command.channels
                              .map((channelId) => {
                                const channel = serverChannels.find(
                                  (ch) => ch.id === channelId
                                );
                                return channel ? (
                                  <span key={channelId} style={mentionStyle}>
                                    #{channel.name}
                                  </span>
                                ) : (
                                  "#deleted-channel"
                                );
                              })
                              .reduce((prev, curr) => [prev, ", ", curr])}
                          </Typography>
                        )}
                        {command.roles.length > 0 && (
                          <Typography variant="subtitle1">
                            Roles:{" "}
                            {command.roles
                              .map((roleId) => {
                                const role = serverRoles.find(
                                  (r) => r.id === roleId
                                );
                                return role ? (
                                  <span key={roleId} style={mentionStyle}>
                                    @{role.name}
                                  </span>
                                ) : (
                                  "@deleted-role"
                                );
                              })
                              .reduce((prev, curr) => [prev, ", ", curr])}
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };


  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <br />
        <Typography variant="h4" align="left">
          Command Restrictions
        </Typography>
        <br />
        <Typography variant="subtitle1" align="left">
          Restrict who can use commands and where they can be used
        </Typography>
      </Grid>
      {commands.some(
        (command) =>
          !command.enabled || command.channels.length || command.roles.length
      ) && (
          <Grid item xs={12}>
            <LegacyWarning />
          </Grid>
        )}
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box paddingLeft={6}>
          {" "}
          {/* Add paddingLeft to this Box */}
          <Grid item xs={12}>
            <br />
            <br />
            <Typography variant="body1">
              To manage command restrictions, please use Discord's Integration
              Settings. You can find this in Discord &gt; Server Settings &gt;
              Integrations.
            </Typography>
            <br />
            <img
              src={DiscordIntegrationsPageImage}
              alt="Discord Integrations Page"
              style={{ display: "block", margin: "0 auto" }}
            />
            <br />
            <Typography variant="body1">
              If you need assistance, please refer to the documentation or
              contact support.
            </Typography>
            <br />
          </Grid>
          {commands.some(
            (command) =>
              !command.enabled ||
              command.channels.length ||
              command.roles.length
          ) && (
              <Grid item xs={12}>
                <Typography variant="h4" align="left">
                  Legacy Command Restrictions
                </Typography>
                <br />
                {renderCommandData()}
              </Grid>
            )}

        </Box>
      </Grid>
    </Grid>
  );
};

export default DashboardCommandSettings;
