// src/components/Footer.js
import React from "react";
import { Container, Typography, Link, Box, useTheme } from "@mui/material";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.background.paper, py: 3 }}>
      <Container>
        <Typography variant="body2" color="text.primary" align="center">
          &copy; {new Date().getFullYear()} Ready Check Software LLC - All
          Rights Reserved.
        </Typography>
        <Typography variant="body2" color="text.primary" align="center">
          <Link href="/privacy-policy" color="inherit" underline="hover">
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link href="/terms-of-service" color="inherit" underline="hover">
            Terms of Use
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
