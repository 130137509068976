import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";

import { useServer } from "../context/ServerContext";
import { useServerSettings } from "../context/ServerSettingsContext";

function ArrayRoleSelector() {
  const { server, serverRoles } = useServer();
  const { roleManagementSettings, setRoleManagementSettings, integrations } =
    useServerSettings();
  const { integrationSlug } = useParams();
  const [deletedRoles, setDeletedRoles] = useState([]);

  const [roleIds, setRoleIds] = useState([]);

  const maxItems =
    server.premium === true &&
    integrations?.[integrationSlug]?.roles?.maxItems > 1
      ? integrations?.[integrationSlug]?.roles?.maxItems
      : 1;

  useEffect(() => {
    const currentRoles =
      roleManagementSettings?.integrations?.[integrationSlug]?.roles || [];
    setRoleIds(currentRoles);

    const validRoleIds = serverRoles.map((role) => role.id);
    const invalidRoleIds = currentRoles.filter(
      (id) => !validRoleIds.includes(id)
    );

    setDeletedRoles(
      invalidRoleIds.map((id) => ({
        id,
        name: "@Deleted-Role",
      }))
    );
  }, [roleManagementSettings, serverRoles, integrationSlug]);

  const handleSelectChange = (event) => {
    if (event.target.value.length <= maxItems) {
      setRoleIds(event.target.value);
      updateRoles(event.target.value);
    }
  };

  const updateRoles = (newRoleIds) => {
    setRoleManagementSettings({
      ...roleManagementSettings,
      integrations: {
        ...roleManagementSettings.integrations,
        [integrationSlug]: {
          ...roleManagementSettings.integrations[integrationSlug],
          roles: newRoleIds,
        },
      },
    });
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ pb: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor={`role-selector`}>Roles</InputLabel>
              <Select
                label="Roles"
                multiple
                value={roleIds}
                onChange={handleSelectChange}
              >
                {[...serverRoles, ...deletedRoles].map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ArrayRoleSelector;
