import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { useServer } from "../context/ServerContext";

const Servers = () => {
  const navigate = useNavigate();
  const { user, isLoading } = useUser();
  const { setServerIcon } = useServer();
  const [servers, setServers] = useState([]);
  const [serversLoaded, setServersLoaded] = useState(false);

  // Handle user authentication
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isLoading && !user) {
      navigate("/");
      return;
    }
  }, [user, isLoading]);

  useEffect(() => {
    const fetchServers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_JEEVES_API_URL}/user/${user.id}/servers`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.bearerToken}`,
            },
          }
        );
        const serversData = await response.json();
        console.log("serversData:", serversData);
        setServers(serversData);
        setServersLoaded(true);
      } catch (error) {
        console.error("Error fetching servers:", error);
        setServersLoaded(true);
      }
    };

    if (user) {
      fetchServers();
    }
  }, [user]);

  if (!serversLoaded) {
    return (
      <Box sx={{ flexGrow: 1, mt: 4, mx: 2 }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ textAlign: "center" }}
        >
          My Servers
        </Typography>
        <Typography
          variant="h6"
          component="p"
          sx={{ textAlign: "center", mt: 4, px: 2 }}
        >
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, mt: 4, mx: 2 }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ textAlign: "center" }}
      >
        My Servers
      </Typography>
      {servers.length === 0 ? (
        <Typography
          variant="h6"
          component="p"
          sx={{ textAlign: "center", mt: 4, px: 2 }}
        >
          👀 Nothing is here... You need to be a member of a Discord server and
          have "Manage Server" or "Administrator" permissions for a server to
          show up here.
        </Typography>
      ) : (
        <Grid container spacing={4}>
          {servers.map((server) => (
            <Grid item key={server.id} xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  height: "100%",
                  borderRadius: 2,
                  boxShadow: 2,
                  p: 2,
                }}
              >
                <Avatar
                  src={
                    server.icon
                      ? `https://cdn.discordapp.com/icons/${server.id}/${server.icon}.png`
                      : ""
                  }
                  alt={server.name}
                  sx={{ width: 128, height: 128 }}
                />
                <CardContent>
                  <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                    {server.name}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    pb: 1,
                  }}
                >
                  {server.hasJeeves ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setServerIcon(server.icon)}
                      to={`/servers/${server.id}/dashboard`}
                      component={RouterLink}
                      sx={{ width: "80%", bgcolor: "primary.dark" }}
                    >
                      Dashboard
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ width: "80%", borderColor: "primary.light" }}
                    >
                      Invite Jeeves
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <br />
    </Box>
  );
};

export default Servers;
