// src/pages/ServerDashboard.js
import React, { useEffect, useRef } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Grid } from "@mui/material";
import ServerSettingsNavMenu from "../components/ServerSettingsNavMenu";
import { useUser } from "../context/UserContext";
import { useServer } from "../context/ServerContext";

import GeneralSettings from "../components/GeneralSettings";
import DashboardCommandSettings from "../components/DashboardCommandSettings";
import RoleManagement from "../components/RoleManagement";
import Integration from "../components/Integration";

import Guilds from "../components/Guilds";
import GuildManagement from "../components/ManageGuild";
import AddGuild from "../components/AddGuild";

const ServerDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { serverId } = useParams();
  const { user, isLoading } = useUser();
  const { server, serverLoaded, loadServer } = useServer();
  const isLoadingServer = useRef(false);

  // Handle user authentication
  useEffect(() => {
    if (!isLoading && !user) {
      navigate("/");
      return;
    }
  }, [user, isLoading, navigate]);

  // Handle server loading
  useEffect(() => {
    if (!user || isLoadingServer.current) return;

    if (serverId && serverId !== server?.id) {
      isLoadingServer.current = true;
      loadServer(serverId, user).finally(() => {
        isLoadingServer.current = false;
      });
    }
  }, [serverId, server, user]);

  if (!serverLoaded || isLoading || isLoadingServer.current) {
    return <div>Loading...</div>;
  }

  if (serverLoaded && !server) {
    return <div>Server not found</div>;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={3} style={{ flex: "0 0 250px" }}>
        <ServerSettingsNavMenu />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Routes>
          <Route
            path="/"
            element={<GeneralSettings />} // Set GeneralSettings as the default component
          />
          <Route
            path="command-restrictions"
            element={<DashboardCommandSettings />}
          />
          <Route path="role-management" element={<RoleManagement />} />
          <Route
            path="role-management/:integrationSlug"
            element={<Integration />}
          />
          <Route path="guilds" element={<Guilds />} />
          <Route path="guilds/add" element={<AddGuild />} />
          <Route path="guilds/:guildId" element={<GuildManagement />} />
        </Routes>
      </Grid>
    </Grid>
  );
};
export default ServerDashboard;
