import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useServer } from "../context/ServerContext";
import { useGuild } from "../context/GuildContext";
import { useUser } from "../context/UserContext";
import WoWGuildCrest from "./GuildCrest";

const Guilds = () => {
  const { availableGuilds, setAvailableGuilds } = useGuild();
  const [loading, setLoading] = useState(true);
  const [maxGuildLimit, setMaxGuildLimit] = useState(1);
  const { server } = useServer();
  const { user } = useUser();

  useEffect(() => {
    // Make an API request to get the guilds
    fetch(`${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/guild`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setAvailableGuilds(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching guilds:", error);
        setLoading(false);
      });

    // Set the max guild limit
    if (server.premiumLevel >= 4) {
      setMaxGuildLimit(3);
    }
  }, []);

  // Assuming you have a deleteGuild API
  const handleDelete = async (guildId, event) => {
    event.stopPropagation();
    // Call delete API and then refetch guilds
    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/guild/${guildId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.bearerToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.ok) {
          setAvailableGuilds(
            availableGuilds.filter((guild) => guild.id !== guildId)
          );
        }
      })
      .catch((error) => {
        console.log("Error deleting guild:", error);
      });
  };

  // Assuming you have a reactivateGuild API
  const handleReactivate = async (guildId, event) => {
    event.stopPropagation();
    // Call reactivate API and then refetch guilds
    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/guild/${guildId}/reactivate`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.bearerToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.ok) {
          // Update the guild state as active in the active guilds list
          setAvailableGuilds(
            availableGuilds.map((guild) => {
              if (guild.id === guildId) {
                guild.active = true;
              }
              return guild;
            })
          );
        }
      })
      .catch((error) => {
        console.log("Error reactivating guild:", error);
      });
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h4" gutterBottom>
        Guilds
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {availableGuilds?.map((guild) => (
            <ListItem
              button
              key={guild.id}
              component={RouterLink}
              to={`/servers/${server.id}/dashboard/guilds/${guild.id}`}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box display="flex" alignItems="center">
                  <Box pr={3}>
                    <WoWGuildCrest data={guild.crest} />
                  </Box>
                  <ListItemText
                    primary={guild.name}
                    secondary={`${guild.realm.toUpperCase()} - ${guild.region.toUpperCase()}`}
                    primaryTypographyProps={{
                      color: "primary.main",
                      fontWeight: "bold",
                    }}
                    secondaryTypographyProps={{ color: "text.secondary" }}
                  />
                </Box>
                <Box>
                  {!guild.active && (
                    <Button
                      onClick={(event) => handleReactivate(guild.id, event)}
                    >
                      Reactivate
                    </Button>
                  )}
                  <Button
                    color="error"
                    onClick={(event) => handleDelete(guild.id, event)}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      )}

      {(!availableGuilds || availableGuilds.length < maxGuildLimit) && (
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            component={RouterLink}
            to={`/servers/${server.id}/dashboard/guilds/add`}
          >
            Add Guild
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Guilds;
