// src/context/GuildContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "./ServerContext";
import { useUser } from "./UserContext";

const GuildContext = createContext();

function useGuild() {
  return useContext(GuildContext);
}

function GuildProvider(props) {
  const { user } = useUser();
  const { server } = useServer();
  const [availableGuilds, setAvailableGuilds] = useState([]);
  const [guild, setGuild] = useState(null);

  const value = {
    availableGuilds,
    setAvailableGuilds,
    guild,
    setGuild,
  };

  return <GuildContext.Provider value={value} {...props} />;
}

export { GuildProvider, useGuild };

// Get All Guilds for a Server
// GET /server/:id:/guild
async function getGuilds(server, user) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/guild`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((body) => {
      if (body.status === "ok") {
        return body.data;
      }
    })
    .catch((err) => console.log(err));
}

// Delete a Guild from a Server
// DELETE /server/:id/guild/:guildId
async function deleteGuild(server, user, guildId) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/guild/${guildId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((body) => {
      if (body.status === "ok") {
        return true;
      }
    })
    .catch((err) => console.log(err));
}

// Create/Register a Guild
// POST /server/:id/guild
async function postGuild(server, user, guild) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/guild`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(guild),
    }
  )
    .then((res) => res.json())
    .then((body) => {
      if (body.status === "ok") {
        return body.data;
      }
    })
    .catch((err) => console.log(err));
}

// Reactivate a deactivated Guild
// GET /server/:id/guild/:guildId/reactivate
async function reactivateGuild(server, user, guildId) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/guild/${guildId}/reactivate`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((body) => {
      if (body.status === "ok") {
        return body.data;
      }
    })
    .catch((err) => console.log(err));
}

// Get roles for a Guild
// GET /server/:id/guild/:guildId/roles
async function getRoles(server, user, guildId) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/guild/${guildId}/roles`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((body) => {
      if (body.status === "ok") {
        return body.data;
      }
    })
    .catch((err) => console.log(err));
}

// Update roles for a Guild
// PUT /server/:id/guild/:guildId/roles
async function putRoles(server, user, guildId, roles) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/guild/${guildId}/roles`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(roles),
    }
  )
    .then((res) => res.json())
    .then((body) => {
      if (body.status === "ok") {
        return body.data;
      }
    })
    .catch((err) => console.log(err));
}
