import React from "react";
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";

const CharacterCard = ({
  character,
  renderButtons,
  renderExtraButtons,
  classes,
  unknownCharacterAvatar,
}) => {
  return (
    <Card
      className={classes.card}
      style={{
        backgroundColor: character.ignored ? "rgba(255, 0, 0, 0.1)" : "",
        opacity: character.hidden ? 0.5 : 1,
      }}
    >
      <CardContent style={{ paddingBottom: 8 }}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item>
            <img
              src={character.thumbnail || unknownCharacterAvatar}
              alt={`${character.name}'s avatar`}
              className={classes.avatar}
              onError={(e) => {
                e.target.src = unknownCharacterAvatar;
              }}
            />
          </Grid>
          <Grid item xs>
            <Grid container direction="column" className={classes.charInfo}>
              <Typography variant="h6">
                {character.name} ({character.realm})
              </Typography>
              <Typography variant="body1" color="textSecondary">
                ({character.level}) - {character.class} - {character.race}
              </Typography>
              {renderButtons && (
                <Box mt={1}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className={classes.actions}
                  >
                    {renderButtons(character)}
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CharacterCard;
