// src/components/LegacyWarning.js
import React from "react";
import { Button, Alert } from "@mui/material";

const LegacyWarning = () => {
  return (
    <Alert
      severity="warning"
      style={{ fontSize: "0.9em", maxWidth: 900, marginLeft: "0" }}
    >
      <div>
        Warning! You have legacy Command Restrictions. Configuring restrictions
        via Jeeves is no longer supported and will be completely turned off in
        the near future. Please Migrate to Discords Integration Settings system
        outlined below. We have listed your existing settings below to help you
        migrate.
      </div>
      <div style={{ textAlign: "center", marginTop: 8 }}>
        <Button variant="outlined" size="small">
          Delete my Legacy Command Restriction Settings
        </Button>
      </div>
    </Alert>
  );
};

export default LegacyWarning;
