// src/pages/Documentation.js
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import DocumentationNav from "../components/DocumentationNav";
import MarkdownRenderer from "../components/MarkdownRenderer";

const Documentation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const docName = location.pathname.replace("/docs/", "") || "index";
  const mdPath = `${docName}.md`;

  useEffect(() => {
    if (location.pathname === "/docs") {
      navigate("/docs/");
    }
  }, [location, navigate]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={3}>
        <DocumentationNav />
      </Grid>
      <Grid item xs={12} sm={9}>
        <MarkdownRenderer mdPath={mdPath} />
      </Grid>
    </Grid>
  );
};

export default Documentation;
