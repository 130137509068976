import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import { useServer } from "../context/ServerContext";
import { useUser } from "../context/UserContext";
import { useGuild } from "../context/GuildContext";
import CharacterClass from "../data/classes.json";

function GuildManagement() {
  const navigate = useNavigate();
  const { serverId, guildId } = useParams();
  const { serverRoles } = useServer();
  const { user } = useUser();
  const { availableGuilds, setGuild, guild } = useGuild();
  const [guildRanks, setGuildRanks] = useState({});
  const [guildRankExamples, setGuildRankExamples] = useState({});
  const [loading, setLoading] = useState(true);
  const [isRefreshQueued, setIsRefreshQueued] = useState(false);
  const guildRankNumbers = [...Array(10).keys()];

  const now = Date.now();
  const lastScanned = new Date(guild?.lastScanTimestamp || now);
  const latestData = new Date(guild?.latestPayloadTimestamp || now);
  const timeSinceLastScanned = now - lastScanned;
  const timeSinceLatestData = now - latestData;
  const disableForceRefresh =
    isRefreshQueued ||
    timeSinceLatestData < 3 * 60 * 60 * 1000 ||
    timeSinceLastScanned < 20 * 60 * 1000;


  useEffect(() => {
    if (guild?.id == guildId) return;
    const selectedGuild = availableGuilds.find((g) => g.id == guildId);
    if (!selectedGuild) {
      navigate(`/servers/${serverId}/dashboard/guilds`);
      return;
    }
    setGuild(selectedGuild);
  }, [availableGuilds, serverId, navigate, guildId]);

  useEffect(() => {
    if (!guildRanks?.guildID) return;
    setLoading(false);
  }, [guildRanks]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/server/${serverId}/guild/${guildId}/roles`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.bearerToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setGuildRanks(response.data);
      })
      .catch((error) => {
        console.log("Error fetching guild ranks:", error);
      });

    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/server/${serverId}/guild/${guildId}/roles/example`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.bearerToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setGuildRankExamples(response.data);
      })
      .catch((error) => {
        console.log("Error fetching guild rank examples:", error);
      });
  }, []);

  const handleSelectChange = (rankId, event) => {
    setGuildRanks({
      ...guildRanks,
      [`rank${rankId}Roles`]: event.target.value,
    });
  };

  const handleForceRefresh = () => {
    setIsRefreshQueued(true); // Disable button and update text
    const url = `${process.env.REACT_APP_JEEVES_API_URL}/server/${serverId}/guild/${guildId}/scan`;
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    })
  };

  const handleSaveSettings = () => {
    const rolesSettings = guildRankNumbers.reduce((acc, rankNum) => {
      const rankRoles = `rank${rankNum}Roles`;
      return {
        ...acc,
        [rankRoles]: guildRanks[rankRoles],
      };
    }, {});

    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/server/${serverId}/guild/${guildId}/roles`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${user.bearerToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rolesSettings),
      }
    ).catch((error) => {
      console.log("Error saving guild ranks:", error);
    });
  };

  const handleGoBack = () => {
    navigate(`/servers/${serverId}/dashboard/guilds`);
  };

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ mt: 3 }}>
      <Button onClick={handleGoBack}>{"< Go Back"}</Button>
      <Typography variant="h4" gutterBottom>
        {guild?.name}
      </Typography>

      {/* Responsive Grid Container */}
      <Grid container spacing={2}>
        {/* Left Column: Role Selectors */}
        <Grid item xs={12} md={8}>
          {guildRankNumbers.map((rankNum) => {
            const exampleChar = guildRankExamples?.[rankNum];
            let color = "inherit"; // default color if no class found
            if (exampleChar) {
              const characterClass = CharacterClass.find(
                (cls) => cls.id === exampleChar.playable_class.id
              );
              if (characterClass) {
                color = characterClass.color;
              }
            }
            return (
              <Box key={rankNum} sx={{ pb: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h6" gutterBottom>
                      Rank {rankNum + 1}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Example:{" "}
                      <span style={{ color: color }}>
                        {exampleChar?.name || "No Characters Found"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor={`role-${rankNum + 1}`}>
                        Choose Role(s)
                      </InputLabel>
                      <Select
                        label="Choose Role(s)"
                        multiple
                        value={guildRanks?.[`rank${rankNum}Roles`] || []}
                        onChange={(event) => handleSelectChange(rankNum, event)}
                      >
                        {serverRoles.map((role) => (
                          <MenuItem key={role.id} value={role.id}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          <Button variant="contained" color="primary" onClick={handleSaveSettings}>
            Save Settings
          </Button>
        </Grid>

        {/* Right Column: Info Boxes */}
        <Grid item xs={12} md={4}>
          <Box sx={{ p: 2, border: "1px solid #ccc", borderRadius: 4, mb: 2 }}>
            <Typography variant="h6">Guild Information</Typography>
            <Typography>Name: {guild?.name}</Typography>
            <Typography>Realm: {guild?.realm}</Typography>
            <Typography>Region: {guild?.region}</Typography>
            <Tooltip title="This is when we last tried to get updated data for your guild">
              <Typography>
                Last Scanned: {lastScanned.toLocaleString()}
              </Typography>
            </Tooltip>
            <Tooltip title="This is how old the current data for your guild in our database is">
              <Typography>
                Latest Guild Data: {latestData.toLocaleString()}
              </Typography>
            </Tooltip>
            <Tooltip
              title={
                disableForceRefresh
                  ? "You cannot refresh if the data is less than 3 hours old or a scan was performed less than 20 minutes ago"
                  : ""
              }
            >
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleForceRefresh}
                  disabled={disableForceRefresh}
                >
                  {isRefreshQueued ? "Queued for Refresh..." : "Force Refresh"}
                </Button>
              </span>
            </Tooltip>
          </Box>

          <Box sx={{ p: 2, border: "1px solid #ccc", borderRadius: 4 }}>
            <Typography variant="h6">Tips and Tricks</Typography>
            <Typography>
              - Blizzard updates guild data every 3 hours if someone has logged out recently.
            </Typography>
            <Typography>
              - Jeeves waits 3 hours after a successful scan before checking hourly for updates.
            </Typography>
            <Typography>
              - Guilds with no active players for 2 weeks will stop being scanned by Jeeves. Reactivate on
              the Guild List page.
            </Typography>
            <Typography>
              - We HIGHLY recommend not attaching Administrator powers to guild ranks due to
              potential delays.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GuildManagement;
