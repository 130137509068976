// src/components/MenuHeader.js
import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Toolbar,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import UserInfo from "./UserInfo";
import { useUser } from "../context/UserContext";
import jeevesIconImage from "../assets/jeeves.jpg";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MenuHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useUser();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <RouterLink
            to="/"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              color: "inherit",
            }}
          >
            <Avatar
              alt="Jeeves Icon"
              src={jeevesIconImage} // Replace with your icon path
              sx={{ mr: 1 }}
            />
            <Typography variant="h6" component="div">
              Jeeves
            </Typography>
          </RouterLink>
        </Box>
        <Box>
          <Button
            variant="text"
            color="secondary"
            component={RouterLink}
            to="/client-download"
            sx={{ ml: 2, fontWeight: "bold" }}
          >
            Desktop Client
          </Button>

          <Button
            variant="text"
            color="inherit"
            component={RouterLink}
            to="/premium"
            sx={{ ml: 2, fontWeight: "bold" }}
          >
            Premium
          </Button>

          <Button
            variant="text"
            color="inherit"
            aria-controls="resources-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            sx={{ ml: 2, fontWeight: "bold" }}
            endIcon={<KeyboardArrowDown />}
          >
            Resources
          </Button>

          {user && (
            <Button
              variant="text"
              color="inherit"
              component={RouterLink}
              to="/servers"
              sx={{ ml: 2, fontWeight: "bold" }}
            >
              Server Control Panel
            </Button>
          )}

          <Menu
            id="resources-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              component={RouterLink}
              to="/docs/"
              onClick={handleMenuClose}
              sx={{ color: "inherit" }}
            >
              Documentation
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="/docs/faq"
              onClick={handleMenuClose}
              sx={{ color: "inherit" }}
            >
              FAQ
            </MenuItem>
          </Menu>

          <Button
            variant="text"
            color="inherit"
            onClick={() => window.open("https://discord.gg/rYRFKpz", "_blank")}
            sx={{
              ml: 2,
              fontWeight: "bold",
              color: "#5865F2", // Official Discord purple color
            }}
            startIcon={<FontAwesomeIcon icon={faDiscord} />}
          >
            Jeeves Discord
          </Button>
        </Box>

        <UserInfo />
      </Toolbar>
    </AppBar>
  );
};

export default MenuHeader;
