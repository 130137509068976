// src/components/DocumentationNav.js
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { List, ListItem, ListItemText, Box, Collapse } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const NavItem = ({ label, path, depth }) => {
  const paddingLeft = 15 + depth * 20; // Set the left padding based on the depth

  return (
    <ListItem
      button
      component={RouterLink}
      to={`/docs/${path}`}
      style={{ paddingLeft }} // Apply the padding as an inline style
    >
      <ListItemText primary={label} />
    </ListItem>
  );
};

const renderNavItems = (navItems, handleClick, openMenus, depth = 0) => {
  return navItems.map((item) => {
    const key = Object.keys(item)[0];
    const value = item[key];
    const isSubMenu = typeof value !== "string";

    if (!isSubMenu) {
      return (
        <NavItem
          key={key}
          label={key}
          path={value.replace(".md", "")}
          depth={depth}
        />
      );
    }

    return (
      <React.Fragment key={key}>
        <ListItem
          button
          onClick={() => handleClick(key)}
          style={{ paddingLeft: 15 + depth * 20 }}
        >
          <ListItemText primary={key} />
          {openMenus[key] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openMenus[key]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderNavItems(value, handleClick, openMenus, depth + 1)}
          </List>
        </Collapse>
      </React.Fragment>
    );
  });
};

const DocumentationNav = () => {
  const [mkdocsJson, setMkdocsJson] = useState(null);
  const [openMenus, setOpenMenus] = useState({});

  useEffect(() => {
    fetch("/documentation/mkdocs.json")
      .then((response) => response.json())
      .then((data) => setMkdocsJson(data));
  }, []);

  const handleClick = (menuKey) => {
    setOpenMenus({ ...openMenus, [menuKey]: !openMenus[menuKey] });
  };

  if (!mkdocsJson) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <List>{renderNavItems(mkdocsJson.nav, handleClick, openMenus)}</List>
    </Box>
  );
};

export default DocumentationNav;
