// src/context/ServerContext.js
import React, { createContext, useContext, useState } from "react";

const ServerContext = createContext();

function useServer() {
  return useContext(ServerContext);
}

function ServerProvider(props) {
  const [server, setServer] = useState(null);
  const [serverLoaded, setServerLoaded] = useState(false);
  const [serverChannels, setServerChannels] = useState([]);
  const [serverRoles, setServerRoles] = useState([]);
  const [serverIcon, setServerIcon] = useState(null);

  const loadServer = (serverId, user) => {
    setServer(null);
    setServerLoaded(false);
    setServerChannels([]);
    setServerRoles([]);

    return fetch(`${process.env.REACT_APP_JEEVES_API_URL}/server/${serverId}/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setServer(data);
        setServerLoaded(true);
        loadChannels(serverId, user);
        loadRoles(serverId, user);
      })
      .catch((error) => {
        console.error("Error fetching server info:", error);
        setServerLoaded(true);
      });
  };

  const loadChannels = (serverId, user) => {
    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/server/${serverId}/channels`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.bearerToken}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`Error fetching server channels: ${response.status}`);
        }
      })
      .then((data) => {
        console.log(data);
        setServerChannels(data);
      })
      .catch((error) => {
        console.error("Error fetching server channels:", error);
      });
  };

  const loadRoles = (serverId, user) => {
    fetch(`${process.env.REACT_APP_JEEVES_API_URL}/server/${serverId}/roles`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`Error fetching server roles: ${response.status}`);
        }
      })
      .then((data) => {
        console.log(data);
        setServerRoles(data);
      })
      .catch((error) => {
        console.error("Error fetching server roles:", error);
      });
  };

  const value = {
    server,
    setServer,
    serverLoaded,
    setServerLoaded,
    serverChannels,
    setServerChannels,
    serverRoles,
    setServerRoles,
    serverIcon,
    setServerIcon,
    loadServer,
    loadChannels,
    loadRoles,
  };

  return <ServerContext.Provider value={value} {...props} />;
}

export { ServerProvider, useServer };
