import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Grid,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useServer } from "../context/ServerContext";
import { useUser } from "../context/UserContext";
import RealmData from "../data/realms.json";

function AddGuild() {
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [realm, setRealm] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { server } = useServer();
  const { user } = useUser();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // API call
    try {
      const response = await fetch(
        `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/guild`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user.bearerToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, region, realm }),
        }
      );
      console.log(response);
      if (!response.ok) {
        const { code } = await response.json();
        switch (code) {
          case "GUILD_ALREADY_REGISTERED":
            setErrorMessage("This guild is already registered to the server.");
            break;
          case "GUILD_LIMIT_EXCEDED":
            setErrorMessage(
              "You have reached the maximum allowed number of guilds registered. Consider upgrading to Jeeves Premium or contacting support."
            );
            break;
          case "OFFICER_ONLY_OPERATION":
            setErrorMessage(
              "Only guild officers can register a guild. Officers are considered the GM and the rank directly under the GM. If the GM has a GM-ALT role, only the GM can process the first time registration."
            );
            break;
          default:
            setErrorMessage(
              "Something went wrong, please contact Jeeves support."
            );
        }
      } else {
        navigate(`/servers/${server.id}/dashboard/guilds`);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Something went wrong, please contact Jeeves support.");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Button
        onClick={() => navigate(`/servers/${server.id}/dashboard/guilds`)}
      >
        {"< Go Back"}
      </Button>
      <Typography variant="h4" gutterBottom>
        Register a new Guild
      </Typography>
      {errorMessage && (
        <Typography variant="body1" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      <TextField
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        required
      />
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="region"
                sx={{
                  backgroundColor: "background.default", // Match the background color
                  paddingLeft: 1,
                  paddingRight: 1,
                  "&.Mui-focused": {
                    backgroundColor: "background.default", // Match the background color when focused
                  },
                }}
              >
                Region
              </InputLabel>
              <Select
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              >
                <MenuItem value="us">US</MenuItem>
                <MenuItem value="eu">EU</MenuItem>
                <MenuItem value="tw">TW</MenuItem>
                <MenuItem value="kr">KR</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="realm"
                sx={{
                  backgroundColor: "background.default", // Match the background color
                  paddingLeft: 1,
                  paddingRight: 1,
                  "&.Mui-focused": {
                    backgroundColor: "background.default", // Match the background color when focused
                  },
                }}
              >
                Realm
              </InputLabel>
              <Select value={realm} onChange={(e) => setRealm(e.target.value)}>
                {RealmData.map(
                  (realm) =>
                    realm.region === region && (
                      <MenuItem key={realm.name} value={realm.slug}>
                        {realm.name}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        sx={{ width: "100%", mt: 2 }}
      >
        Save
      </Button>
    </Box>
  );
}

export default AddGuild;
