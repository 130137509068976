import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  Button,
  Box,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Link, useLocation } from "react-router-dom";
import GeneralIcon from "@mui/icons-material/Settings";
import CommandRestrictionsIcon from "@mui/icons-material/Lock";
import GuildsIcon from "@mui/icons-material/MilitaryTech";
import RoleManagementIcon from "@mui/icons-material/Group";
import ChangeServerIcon from "@mui/icons-material/SwapHoriz";

import { useServer } from "../context/ServerContext";

const useStyles = makeStyles((theme) => ({
  navList: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    width: "100%",
  },
  listItem: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  activeListItem: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  listItemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
  },
}));

const ServerSettingsNavMenu = () => {
  const { server } = useServer();
  const classes = useStyles();

  const location = useLocation();
  const path = location.pathname;

  const isActive = (route) => {
    return path.includes(route);
  };

  return (
    <Box
      style={{
        width: 250,
        borderRight: "1px solid rgba(255, 255, 255, 0.12)",
        backgroundColor: "#1a1a1a",
        boxSizing: "border-box",
        paddingRight: "16px",
        minHeight: "100vh", // Set the minHeight to 100% of the viewport height
        position: "sticky", // Set the position to 'sticky'
        top: 0, // Set the top to 0
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={1}
        mt={3}
      >
        <Avatar
          src={
            server.icon
              ? `https://cdn.discordapp.com/icons/${server.id}/${server.icon}.png`
              : ""
          }
        />
        <Box ml={1}>
          <ListItemText primary={server.name} />
        </Box>
      </Box>
      <List className={classes.navList}>
        <ListItem
          button
          component={Link}
          to=""
          className={`${classes.listItem} ${
            isActive("general") ? classes.activeListItem : ""
          }`}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <GeneralIcon />
          </ListItemIcon>
          <ListItemText primary="General" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="command-restrictions"
          className={`${classes.listItem} ${
            isActive("command-restrictions") ? classes.activeListItem : ""
          }`}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <CommandRestrictionsIcon />
          </ListItemIcon>
          <ListItemText primary="Command Restrictions" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="role-management"
          className={`${classes.listItem} ${
            isActive("role-management") ? classes.activeListItem : ""
          }`}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <RoleManagementIcon />
          </ListItemIcon>
          <ListItemText primary="Role Management" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="guilds"
          className={`${classes.listItem} ${
            isActive("guilds") ? classes.activeListItem : ""
          }`}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <GuildsIcon />
          </ListItemIcon>
          <ListItemText primary="Guilds" />
        </ListItem>
      </List>
      <Box mt={2} display="flex" justifyContent="center">
        <Button
          variant="outlined"
          color="primary"
          to={`/servers`}
          component={Link}
          startIcon={<ChangeServerIcon />}
        >
          Change Server
        </Button>
      </Box>
    </Box>
  );
};

export default ServerSettingsNavMenu;
