function drawWoWGuildCrest(data, canvas) {
  const rgbaToHex = (rgba) => {
    let hex = ((rgba.r << 16) | (rgba.g << 8) | rgba.b).toString(16);
    return hex.padStart(6, "0");
  };

  let crestImageUrl = `https://render.worldofwarcraft.com/us/guild/tabards/emblem_${data.emblem.id
    .toString()
    .padStart(2, "0")}.png`;
  let borderImageUrl = `https://render.worldofwarcraft.com/us/guild/tabards/border_${data.border.id
    .toString()
    .padStart(2, "0")}.png`;
  let iconColor = rgbaToHex(data.emblem.color.rgba);
  let borderColor = rgbaToHex(data.border.color.rgba);
  let backgroundColor = rgbaToHex(data.background.color.rgba);

  // Ensure the canvas is not null
  if (!canvas) return;

  let ctx = canvas.getContext("2d");

  // Log URLs to ensure they are correct
  //console.log('Crest Image URL:', crestImageUrl);
  //console.log('Border Image URL:', borderImageUrl);

  // Load images
  let crestImage = new Image();
  let borderImage = new Image();

  crestImage.onload = function () {
    borderImage.onload = function () {
      // Draw background
      ctx.fillStyle = `#${backgroundColor}`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw crest
      ctx.drawImage(crestImage, 0, 0, canvas.width, canvas.height);

      // Apply color to crest
      ctx.globalCompositeOperation = "source-in";
      ctx.fillStyle = `#${iconColor}`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.globalCompositeOperation = "source-over";
      // Draw border
      ctx.drawImage(borderImage, 0, 0, canvas.width, canvas.height);

      // Apply color to border
      ctx.globalCompositeOperation = "source-in";
      ctx.fillStyle = `#${borderColor}`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    };
    borderImage.onerror = function () {
      console.error('Failed to load border image:', borderImageUrl);
    };
    borderImage.src = borderImageUrl;
  };
  crestImage.onerror = function () {
    console.error('Failed to load crest image:', crestImageUrl);
  };
  crestImage.src = crestImageUrl;
}

export default drawWoWGuildCrest;
