// src/App.js
import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { UserProvider } from "./context/UserContext";
import { ServerProvider } from "./context/ServerContext";
import { ServerSettingsProvider } from "./context/ServerSettingsContext";
import { GuildProvider } from "./context/GuildContext";
import { Box } from "@mui/material";
import MenuHeader from "./components/MenuHeader";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import BattlenetAuthorization from "./pages/BattlenetAuthorization";
import Servers from "./pages/Servers";
import DesktopClient from "./pages/DesktopClient";
import Documentation from "./pages/Documentation";
import ServerDashboard from "./pages/ServerDashboard";
import PremiumPage from "./pages/Premium";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1E1E1E",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserProvider>
        <ServerProvider>
          <ServerSettingsProvider>
            <GuildProvider>
              <Router>
                <div
                  style={{ backgroundColor: theme.palette.background.default }}
                >
                  <MenuHeader />
                  <Box sx={{ minHeight: "calc(100vh - 64px)" }}>
                    <Routes>
                      <Route path="*" element={<HomePage />} />
                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                      />
                      <Route
                        path="/terms-of-service"
                        element={<TermsOfService />}
                      />
                      <Route path="/premium" element={<PremiumPage />} />
                      <Route
                        path="/battlenet-authorization"
                        element={<BattlenetAuthorization />}
                      />
                      <Route
                        path="/client-download"
                        element={<DesktopClient />}
                      />
                      <Route path="/servers" element={<Servers />} />
                      <Route
                        path="/servers/:serverId/dashboard/*"
                        element={<ServerDashboard />}
                      />
                      <Route
                        path="/docs/"
                        element={<Documentation docName="index.md" />}
                      />
                      <Route path="/docs/*" element={<Documentation />} />
                    </Routes>
                  </Box>
                  <Footer />
                </div>
              </Router>
            </GuildProvider>
          </ServerSettingsProvider>
        </ServerProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
