// src/context/ServerSettingsContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "./ServerContext";
import { useUser } from "./UserContext";

const ServerSettingsContext = createContext();

function useServerSettings() {
  return useContext(ServerSettingsContext);
}

function ServerSettingsProvider(props) {
  const { user } = useUser();
  const { server } = useServer();

  const [roleManagementSettings, setRoleManagementSettings] = useState(null);
  const [integrations, setIntegrations] = useState({});

  const [realm, setRealm] = useState("");
  const [region, setRegion] = useState("");
  const [welcomeMode, setWelcomeMode] = useState("");
  const [welcomeChannel, setWelcomeChannel] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");

  const [generalSettingsLoading, setGeneralSettingsLoading] = useState(true);
  const [roleManagementLoading, setRoleManagementLoading] = useState(true);
  const [integrationsLoading, setIntegrationsLoading] = useState(true);

  // Reset Settings When Server Changes or User Logs Out
  useEffect(() => {
    setRoleManagementSettings(null);
    setRealm("");
    setRegion("");
    setWelcomeMode("");
    setWelcomeChannel("");
    setWelcomeMessage("");
    setGeneralSettingsLoading(true);
    setRoleManagementLoading(true);
    setIntegrationsLoading(true);
  }, [server, user]);

  // Load Settings When Server Changes
  useEffect(() => {
    if (!server || !user) return;

    // Load General Settings
    getGeneralSettings(server, user)
      .then((data) => {
        if (data) {
          setRealm(data.realm);
          setRegion(data.region);
          setWelcomeMode(data.welcomeMode);
          setWelcomeChannel(data.welcomeChannel);
          setWelcomeMessage(data.welcomeMessage);
        }
      })
      .finally(() => setGeneralSettingsLoading(false));

    // Load Role Management Settings
    getRoleManagementSettings(server, user)
      .then((data) => {
        if (data) {
          setRoleManagementSettings(data);
        }
      })
      .finally(() => setRoleManagementLoading(false));

    // Load Integrations
    getIntegrations(server, user)
      .then((data) => {
        if (data) {
          setIntegrations(data);
        }
      })
      .finally(() => setIntegrationsLoading(false));

  }, [server, user]);

  const saveGeneralSettings = () => {
    if (!server || !user) return;
    const settings = {
      region,
      realm,
      welcomeMode,
      welcomeChannel,
      welcomeMessage,
    };
    putGeneralSettings(server, user, settings).then((success) => {
      if (success) {
        console.log("Settings saved successfully!");
      }
    });
  };

  const saveRoleManagementSettings = () => {
    if (!server || !user) return;
    putRoleManagementSettings(server, user, roleManagementSettings).then(
      (success) => {
        if (success) {
          console.log("Settings saved successfully!");
        }
      }
    );
  };

  const value = {
    roleManagementSettings,
    setRoleManagementSettings,
    integrations,
    realm,
    setRealm,
    region,
    setRegion,
    welcomeMode,
    setWelcomeMode,
    welcomeChannel,
    setWelcomeChannel,
    welcomeMessage,
    setWelcomeMessage,

    saveGeneralSettings,
    saveRoleManagementSettings,

    generalSettingsLoading,
    roleManagementLoading,
    integrationsLoading,
  };

  return <ServerSettingsContext.Provider value={value} {...props} />;
}

export { ServerSettingsProvider, useServerSettings };

// GET /server/:id/settings/general
async function getGeneralSettings(server, user) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/settings/general`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((body) => {
      if (body.status === "ok") {
        return body.data;
      }
    })
    .catch((err) => console.log(err));
}

// PUT /server/:id/settings/general
async function putGeneralSettings(server, user, settings) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/settings/general`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(settings),
    }
  )
    .then((res) => res.json())
    .then((body) => {
      if (body.status === "ok") {
        return true;
      }
    })
    .catch((err) => console.log(err));
}

// GET /server/:id/settings/role-management
async function getRoleManagementSettings(server, user) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/settings/rolemanagement`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((body) => {
      if (body.status === "ok") {
        return body.data;
      }
    })
    .catch((err) => console.log(err));
}

// PUT /server/:id/settings/role-management
async function putRoleManagementSettings(server, user, settings) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/settings/rolemanagement`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(settings),
    }
  )
    .then((res) => res.json())
    .then((body) => {
      if (body.status === "ok") {
        return true;
      }
    })
    .catch((err) => console.log(err));
}

// GET /server/:id/settings/rolemanagement/integrations
async function getIntegrations(server, user) {
  return fetch(
    `${process.env.REACT_APP_JEEVES_API_URL}/server/${server.id}/settings/rolemanagement/integrations`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.bearerToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((body) => {
      return body;
    })
    .catch((err) => console.log(err));
}
