// src/pages/HomePage.js
import React from "react";
import {
  Typography,
  Button,
  Container,
  Grid,
  Box,
  Paper,
  Link,
  Avatar,
} from "@mui/material";

import backgroundImage from "../assets/background.png";
import lookupFeatureImage from "../assets/features/lookup_feature.png";
import keystoneFeatureImage from "../assets/features/keystone_feature.png";
import roleManagementFeatureImage from "../assets/features/role_management_feature.png";
import officerToolsFeatureImage from "../assets/features/jeeves_officer_tools.png";
import funFeatureImage from "../assets/features/fun_and_games_feature.png";
import recruitmentFeatureImage from "../assets/features/recruitment_feature.png";

import wowheadServerIcon from "../assets/server_icons/server_wowhead.webp";
import mythicPlusFriendsServerIcon from "../assets/server_icons/server_mythic_plus_friends.webp";
import tsmServerIcon from "../assets/server_icons/server_tsm.webp";
import warcraftPetsServerIcon from "../assets/server_icons/server_warcraft_pets.webp";
import raiderioServerIcon from "../assets/server_icons/server_raiderio.webp";
import wowServerIcon from "../assets/server_icons/server_wow.webp";
import recruitmentServerIcon from "../assets/server_icons/server_recruitment.webp";

const botInviteURL = "https://api.jeeves.bot/invite";

const features = [
  {
    title: "Role Management",
    description:
      "Get promoted based on your in-game achievements. Jeeves links to your BattleNet account and monitors your characters progression.",
    image: roleManagementFeatureImage,
  },
  {
    title: "Fun & Games",
    description:
      "Enjoy recreations of some the best WoW mini-games directly in Discord. Trivia, Gambling, Rolling, you can even check if somebody said [Thunderfury, Blessed Blade of the Windseeker].",
    image: funFeatureImage,
  },
  {
    title: "Recruitment Tools",
    description:
      "Enjoy a live feed of characters that are looking for a guild with many options to filter what you are looking for.",
    image: recruitmentFeatureImage,
  },
  {
    title: "Keystone Tracking",
    description:
      "Track what keystones your guild has. Looking for a 25 Academy run? Jeeves will show you who has one.",
    image: keystoneFeatureImage,
  },
  {
    title: "Officer Tools",
    description:
      "Enjoy loads of tools to help you manage your guild. From simple commands that mute/unmute all non-officers in voice. A summon command that drags all your raiders into the raid channel. To a full raid management system that allows you to create, manage, and track your raids.",
    image: officerToolsFeatureImage,
  },
  {
    title: "Lookup Tools",
    description:
      "Lookup Characters & Guilds. Pricecheck items. Check your character's progression. All from within Discord.",
    image: lookupFeatureImage,
  },
  // ... add more features here
];

const popularServers = [
  {
    name: "WoWHead",
    icon: wowheadServerIcon,
  },
  {
    name: "/r/wow",
    icon: wowServerIcon,
  },
  {
    name: "WarcraftPets",
    icon: warcraftPetsServerIcon,
  },
  {
    name: "Mythic Plus Friends",
    icon: mythicPlusFriendsServerIcon,
  },
  {
    name: "TradeSkillMaster",
    icon: tsmServerIcon,
  },
  {
    name: "Recruitment & Community [NA/OC]",
    icon: recruitmentServerIcon,
  },
  {
    name: "RaiderIO",
    icon: raiderioServerIcon,
  },
];

const HomePage = () => {
  return (
    <div>
      <Paper
        sx={{
          py: 30,
          mb: 4,
          textAlign: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Typography variant="h2" color="common.white" gutterBottom>
            Level Up your Discord Server with Jeeves
          </Typography>
          <Typography
            variant="subtitle1"
            color="common.white"
            gutterBottom
            sx={{ maxWidth: "80%", mx: "auto" }}
          >
            Millions of users use Jeeves to automate and enhance their World of
            Warcraft discord servers.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            href={botInviteURL}
            sx={{ mt: 4 }}
          >
            Invite Jeeves
          </Button>
        </Container>
      </Paper>

      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          pb: 8,
        }}
      >
        <Container>
          <Grid container spacing={6}>
            {" "}
            {/* Increased spacing */}
            {features.slice(0, 6).map((feature, index) => (
              <Grid item xs={12} key={index}>
                <Grid container alignItems="center" spacing={4}>
                  {index % 2 === 0 && (
                    <Grid item xs={12} sm={6}>
                      <img
                        src={feature.image}
                        alt={feature.title}
                        width="100%"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        pl: index % 2 === 0 ? 0 : 2,
                        pr: index % 2 === 0 ? 2 : 0,
                      }}
                    >
                      {" "}
                      {/* Added padding */}
                      <Typography variant="h4" gutterBottom fontWeight="bold">
                        {" "}
                        {/* Updated styling */}
                        {feature.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {feature.description}
                      </Typography>
                      <Link href={botInviteURL} underline="hover">
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ mt: 2 }}
                        >
                          Add Jeeves to your server
                        </Button>
                      </Link>
                    </Box>
                  </Grid>
                  {index % 2 !== 0 && (
                    <Grid item xs={12} sm={6}>
                      <img
                        src={feature.image}
                        alt={feature.title}
                        width="100%"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Box sx={{ mt: 8 }}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color="common.white"
          >
            Popular Servers using Jeeves
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {popularServers.map((server, index) => (
              <Grid item key={index}>
                <Avatar
                  alt={server.name}
                  src={server.icon}
                  sx={{ width: 64, height: 64 }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default HomePage;
