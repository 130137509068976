// src/pages/PrivacyPolicy.js
import React, { useEffect } from "react";
import { Container, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  useEffect(() => {
    console.log("Privacy Policy Page Loaded");
  }, []);

  return (
    <Container>
      <br />
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <br />
      <Typography variant="body1" paragraph>
        Privacy Policy for [Jeeves]
      </Typography>
      <Typography variant="body1" paragraph>
        Last Updated: [11/17/2024]
      </Typography>
      <Typography variant="body1" paragraph>
        This privacy policy ("Policy") outlines the types of data collected by
        [Jeeves] ("we," "us," or "our") and how we use and protect that data. By
        using our bot, you agree to the terms of this Policy.
      </Typography>
      <Typography variant="body1" paragraph>
        1. Data Collection
      </Typography>
      <Typography variant="body1" paragraph>
        We collect data from the following sources:
      </Typography>
      <Typography variant="body1" paragraph>
        a) Discord: We collect data from Discord as necessary to enable features
        and improve our bot. This data may include your Discord username,
        profile picture, messages, and other relevant information.
      </Typography>
      <Typography variant="body1" paragraph>
        b) World of Warcraft: We collect data from World of Warcraft in
        accordance with their Developer Terms of Service and Policies. This data
        may include your World of Warcraft username, character information, game
        progress, and other relevant information.
      </Typography>
      <Typography variant="body1" paragraph>
        c) Desktop Application: Our desktop application primarily collects data
        from World of Warcraft to enable its features and enhance your experience.
        In addition, the application may collect and report general information
        used for analytics, performance optimization, error monitoring, and debugging.
      </Typography>
      <Typography variant="body1" paragraph>
        2. Data Usage
      </Typography>
      <Typography variant="body1" paragraph>
        We use the data we collect to provide, improve, and personalize our
        bot's features, and ensure compliance with the Developer Terms of
        Service and Policies of Discord and World of Warcraft.
      </Typography>
      <Typography variant="body1" paragraph>
        3. Data Sharing
      </Typography>
      <Typography variant="body1" paragraph>
        We may share certain data with third-party partners as necessary to
        enable features or improve our bot. We do not sell your data. Our
        third-party partners are required to adhere to the same privacy
        standards and protect your data as outlined in this Policy.
      </Typography>
      <Typography variant="body1" paragraph>
        4. Data Retention
      </Typography>
      <Typography variant="body1" paragraph>
        We retain your data only for as long as necessary to provide our bot's
        services and features or as required by law.
      </Typography>
      <Typography variant="body1" paragraph>
        5. Data Protection
      </Typography>
      <Typography variant="body1" paragraph>
        We take reasonable measures to protect your data from unauthorized
        access, disclosure, alteration, or destruction. However, no method of
        transmission or storage is 100% secure, so we cannot guarantee the
        absolute security of your data.
      </Typography>
      <Typography variant="body1" paragraph>
        6. Changes to This Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to update or modify this Policy at any time. Any
        changes will be effective immediately upon posting the updated Policy on
        this page. Your continued use of our bot after any changes to the Policy
        constitutes your acceptance of the updated Policy.
      </Typography>
      <Typography variant="body1" paragraph>
        7. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about this Policy, please contact
        us at [support@jeeves.bot].
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
