import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import { useServer } from "../context/ServerContext";
import { useServerSettings } from "../context/ServerSettingsContext";

function RangeRoleMap() {
  const { server, serverRoles } = useServer();
  const { roleManagementSettings, setRoleManagementSettings, integrations } =
    useServerSettings();
  const { integrationSlug } = useParams();
  const [deletedRoles, setDeletedRoles] = useState([]);

  const [rows, setRows] = useState([]);
  const maxItems = integrations?.[integrationSlug]?.roleMap?.maxItems || 20;

  useEffect(() => {
    const roleMap =
      roleManagementSettings?.integrations?.[integrationSlug]?.roleMap || {};
    setRows(
      Object.keys(roleMap).map((value) => ({
        id: value,
        value,
        roles: roleMap[value],
      }))
    );

    const selectedRoleIds = Object.values(roleMap).flat();
    const validRoleIds = serverRoles.map((role) => role.id);
    const invalidRoleIds = selectedRoleIds.filter(
      (id) => !validRoleIds.includes(id)
    );

    setDeletedRoles(
      invalidRoleIds.map((id) => ({
        id,
        name: "@Deleted-Role",
      }))
    );
  }, [roleManagementSettings, serverRoles, integrationSlug]);

  const maxSelectItems =
    server.premium === true &&
      integrations?.[integrationSlug]?.roleMap?.maxEach > 1
      ? integrations?.[integrationSlug]?.roleMap?.maxEach
      : 1;

  const range = integrations?.[integrationSlug]?.roleMap?.range || [0, 7500];

  const handleSelectChange = (rowId, event) => {
    const selectedRoles = Array.isArray(event.target.value)
      ? event.target.value
      : [event.target.value]; // Ensure selected roles are an array

    const updatedRows = rows.map((row) =>
      row.id === rowId ? { ...row, roles: selectedRoles } : row
    );

    setRows(updatedRows);
    updateRoleMap(updatedRows);
  };



  const handleValueChange = (rowId, event) => {
    const updatedRows = rows.map((row) =>
      row.id === rowId ? { ...row, value: event.target.value } : row
    );
    setRows(updatedRows);
  };

  const updateRoleMap = (rowsData) => {
    const updatedRoleMap = rowsData.reduce((acc, row) => {
      if (row.value) {
        // Ensure roles are always stored as an array
        acc[row.value] = Array.isArray(row.roles) ? row.roles : [row.roles];
      }
      return acc;
    }, {});

    setRoleManagementSettings({
      ...roleManagementSettings,
      integrations: {
        ...roleManagementSettings.integrations,
        [integrationSlug]: {
          ...roleManagementSettings.integrations[integrationSlug],
          roleMap: updatedRoleMap,
        },
      },
    });
  };


  const handleBlur = (rowId) => {
    updateRoleMap(rows);
  };

  const addRow = () => {
    if (rows.length < maxItems) {
      const newRowId = `new-${Date.now()}`;
      const newRow = { id: newRowId, value: "", roles: [] }; // Ensure roles is initialized as an empty array
      setRows([...rows, newRow]);
    }
  };


  const removeRow = (rowId) => {
    const updatedRows = rows.filter((row) => row.id !== rowId);
    setRows(updatedRows);
    updateRoleMap(updatedRows);
  };

  return (
    <Box sx={{ mt: 3 }}>
      {rows.map((row) => (
        <Box key={row.id} sx={{ pb: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Value"
                type="number"
                inputProps={{ min: range[0], max: range[1] }}
                value={row.value}
                onChange={(event) => handleValueChange(row.id, event)}
                onBlur={() => handleBlur(row.id)}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor={`role-selector-${row.id}`}>
                  Roles
                </InputLabel>
                <Select
                  label="Roles"
                  multiple={maxSelectItems > 1 ? true : false}
                  value={row.roles}
                  onChange={(event) => handleSelectChange(row.id, event)}
                >
                  {[...serverRoles, ...deletedRoles].map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => removeRow(row.id)}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        </Box>
      ))}
      {rows.length < maxItems && (
        <Box sx={{ pt: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addRow}
          >
            Add Entry
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default RangeRoleMap;
